
// Body
$body-bg: #fff;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Color
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$color-initial: #fff;
$color-primary: #2e31ca;
$color-secondary: #606c76;
$color-tertiary: #f4f5f6;
$color-quaternary: #d1d1d1;
$color-quinary: #e1e1e1;

// Typography
$font-family: 'Poiret One', cursive;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: $color-secondary;

// Navbar
$navbar-default-bg: #f4f5f6;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($color-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
