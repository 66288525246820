
// Fonts
@import url(https://fonts.googleapis.com/css?family=Poiret+One&subset=cyrillic);

// Variables
@import "variables";

@import "~milligram";

@import "navigation";

html, body {
  background-color: $body-bg;
  color: $text-color;
  font-family: $font-family;
  font-weight: 100;
  height: 100vh;
  margin: 0;
}

.full-height {
  height: 100vh;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}

.top-right {
  position: absolute;
  right: 10px;
  top: 18px;
}

.content {
  text-align: center;
}

.title {
  font-size: 84px;
}

.links > a {
  color: #636b6f;
  padding: 0 25px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: .1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.links > a:hover {
  text-decoration: underline;
}

.m-b-md {
  margin-bottom: 30px;
}

.wrapper {
  .container {
    max-width: 80rem;
  }
}

.container {

}

