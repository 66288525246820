@import "variables";

.navigation
{
  background: $navbar-default-bg;
  border-bottom: .1rem solid $color-quaternary;
  display: block;
  height: 5.2rem;
  left: 0;
  max-width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;

  .container
  {
    padding-bottom: 0;
    padding-top: 0;
  }

  .navigation-title,
  .title,
  .navigation-link
  {
    display: inline;
    font-size: 1.6rem;
    line-height: 5.2rem;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
    color: $color-primary;
  }

  .navigation-title,
  .title
  {
    color: $color-secondary;
    font-family: $font-family;
    position: relative;
  }

  .navigation-list
  {
    list-style: none;
    margin-bottom: 0;
    margin-right: 5rem;

    .navigation-item {
      float: left;
      margin-bottom: 0;
      margin-left: 2.5rem;
      position: relative;

      &:hover {
        .popover {
          display: block;
        }
      }

      .popover {
        background: $color-initial;
        border: .1rem solid $color-quaternary;
        border-radius: .4rem;
        display: none;
        filter: drop-shadow(0 0 .6rem rgba(0,0,0,.1));
        left: 50%;
        min-width: 13.4rem;
        position: absolute;
        top: 94%;
        transform: translateX(-50%);

        .popover-list {
          list-style: none;
          margin: 0;
          padding: 0;

          .popover-item {
            margin: 0;
            padding: 0;

            &:first-child .popover-link {
              border-radius: .4rem .4rem 0 0;
            }

            .popover-link {
              border-bottom: .1rem solid $color-quaternary;
              color: $color-secondary;
              display: block;
              font-size: 1.2rem;
              padding: .8rem 2rem;
              position: relative;
              text-align: center;
              text-decoration: none;

              &:hover {
                background: $color-primary;
                border-bottom-color: $color-primary;
                color: #fff;
              }
            }
          }
        }

        &:after, &:before {
          border: 1rem solid transparent;
          content: " ";
          height: 0;
          left: 50%;
          pointer-events: none;
          position: absolute;
          right: 1.7rem;
          top: 0;
          width: 0;
        }

        &:before {
          border-bottom-color: $color-initial;
          transform: translate(-50%,-102%);
        }
      }
    }
  }
}